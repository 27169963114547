import { EnvType } from '@/helpers/services/api';

import { getUserPreferences } from '@/helpers/utils/localStorage';

export const envs = [
  { value: EnvType['EVAL'], label: 'Evaluation' },
  { value: EnvType['PENTEST'], label: 'Pentest' },
  { value: EnvType['PROD'], label: 'Production' }
];

export const constants = {
  application: { label: 'Application', placeholder: 'application' },
  version: { label: 'Version', placeholder: 'Click to create' },
  time: {
    label: 'Time Range',
    placeholder: 'All',
    start: 'Start Time',
    end: 'End Time',
    all: 'All Time',
    resetBtnLabel: 'Reset to Default'
  },
  noVersion: 'No Application Version Selected',
  refreshTooltip: 'Refresh Content',
  manageBtnLabel: 'Manage Applications >',
  addVersionBtnLabel: 'Add New Version',
  internalServerErr: {
    title: 'Sorry, we had some technical issues during your last operation',
    buttonLabel: 'Reload',
    imgAlt: 'magnifying glass illustration'
  }
};

export const srcRadioOptions = () => {
  return Array.isArray(getUserPreferences()?.activeEnvs)
    ? envs.filter(env => getUserPreferences().activeEnvs.includes(env.value))
    : envs;
};
