import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { AppFiltersTime } from './AppFiltersTime/AppFiltersTime';
import { RotateContainer } from './RotateContainer/RotateContainer';
import { InternalServerError } from './InternalServerError/InternalServerError';
import { OverviewNoAppList } from './../../Overview/OverviewNoAppList/OverviewNoAppList';
import { SamplesNoVersionSelected } from '@/components/Samples/SamplesNoVersionSelected/SamplesNoVersionSelected';

import { StyledSelect, StyledToolTip } from '@/components/lib';
import { StyledAddCircleOutline } from '@/components/Onboarding/Onboarding.styles';
import {
  AppFiltersContainer,
  AppFiltersInnerContainer,
  AppFiltersVersionsDropdown,
  StyledRefreshIcon,
  AppFiltersInnerItemContainer,
  AppFiltersEnvContainer,
  StyledLabelText
} from './AppFilters.styles';

import { routes } from '@/helpers/routes';
import { resError } from '@/helpers/services/resHandlers';
import { getTimestamp } from '@/helpers/utils/getTimestamp';
import { getAppStorage, setStorageItem, storageKeys } from '@/helpers/utils/localStorage';
import {
  EnvType,
  ApplicationSchema,
  ApplicationVersionSchema,
  useListApps,
  getDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet
} from '@/helpers/services/api';

import { constants, srcRadioOptions } from './appFilters.constants';

interface AppFiltersProps {
  isOverview?: boolean;
  refreshValue?: boolean;
  initialLoading?: boolean;
  samplesSelectedApp?: { appId: number; versionId: number; type: EnvType; timestamp: { start: number; end: number } };
  refresh?: () => void;
  additionalCallback?: () => void;
  resetFiltersOnAppChange?: () => void;
  setInitialLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedApp: (arg: {
    appId: number;
    versionId: number;
    type: EnvType;
    timestamp: { start: number; end: number };
  }) => void;
}

export const AppFilters = (props: AppFiltersProps) => {
  const {
    isOverview,
    refreshValue,
    initialLoading,
    samplesSelectedApp,
    refresh,
    setSelectedApp,
    setInitialLoading,
    additionalCallback,
    resetFiltersOnAppChange
  } = props;
  const { appName, versionName, type, timestamp: timeStampStorage } = getAppStorage();

  const { pathname, query, replace, push } = useRouter();
  const { data: applications, isFetched, isFetching, refetch } = useListApps();

  const appNameQuery = query?.appName && query.appName !== 'undefined' && decodeURIComponent(`${query?.appName}`);
  const versionNameQuery =
    query?.versionName && query.versionName !== 'undefined' && decodeURIComponent(`${query?.versionName}`);
  const envQuery = query?.env && query.env !== 'undefined' && (decodeURIComponent(`${query?.env}`) as EnvType);
  const startTimeQuery =
    query?.start_time_epoch && query.start_time_epoch !== 'undefined' && Number(query?.start_time_epoch);
  const endTimeQuery = query?.end_time_epoch && query.end_time_epoch !== 'undefined' && Number(query?.end_time_epoch);

  const [envType, setEnvType] = useState(envQuery || type || EnvType['EVAL']);
  const [application, setApplication] = useState(appNameQuery || appName || '');
  const [version, setVersion] = useState(versionNameQuery || versionName || '');
  const [timestamp, setTimestamp] = useState<{ start: number; end: number }>({
    start: startTimeQuery || timeStampStorage?.start,
    end: endTimeQuery || timeStampStorage?.end
  });

  const selectedApp = (() => {
    if (Array.isArray(applications)) {
      const selected = Array.isArray(applications)
        ? applications.find(app => app.name === application)
        : ({} as ApplicationSchema);
      return (
        selected || (applications.length > 0 && (application || (!appNameQuery && !appName)) ? applications[0] : null)
      );
    } else {
      return null;
    }
  })();
  const selectedAppId = selectedApp?.id || NaN;
  const selectedVersion = (() => {
    const selected = Array.isArray(selectedApp?.versions)
      ? selectedApp?.versions?.find(val => val?.name === version)
      : ({} as ApplicationVersionSchema);
    return selected || selectedApp?.versions[0] || null;
  })();

  const ignoreTimeFilter = isOverview && EnvType['PROD'] !== envType;
  const samplesNoData = !(selectedAppId && selectedVersion) && isFetched && !isFetching && !isOverview;

  const handleResetDates = async () => {
    await getDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet(
      Number(selectedVersion?.id),
      {
        env_type: envType
      }
    )
      .then(defaultTimes => {
        if (defaultTimes?.start_time && defaultTimes?.end_time && envType === EnvType['PROD']) {
          setTimestamp({ start: defaultTimes.start_time, end: defaultTimes.end_time });
        } else {
          setTimestamp(
            envType === EnvType['PROD'] ? { start: getTimestamp(21), end: getTimestamp(0) } : { start: NaN, end: NaN }
          );
        }
      })
      .catch(() => {
        setTimestamp(
          envType === EnvType['PROD'] ? { start: getTimestamp(21), end: getTimestamp(0) } : { start: NaN, end: NaN }
        );
      });
  };

  const handleApplicationChange = (state: string | undefined) => {
    setApplication(state);
    const newAppSelected = Array.isArray(applications)
      ? applications.find(el => el.name === state)
      : ({} as ApplicationSchema);
    const newAppVersion = newAppSelected?.versions[0]?.name;

    newAppVersion && setVersion(newAppVersion);
  };

  const handleQueryChange = (
    appState?: string,
    versionsState?: string,
    envTypeName?: string,
    timestampState?: { start: number; end: number }
  ) => {
    if (Array.isArray(applications)) {
      const selectedCurApp = appState ? applications.find(app => app.name === appState) : selectedApp;
      const selectedCurVersion =
        versionsState && Array.isArray(selectedCurApp?.versions)
          ? selectedCurApp?.versions.find(val => val?.name === versionsState)
          : selectedVersion;
      const queryCopy = { ...query };
      const isPentestEnvChange =
        samplesSelectedApp?.type !== envType && (envType === 'PENTEST' || samplesSelectedApp?.type === 'PENTEST');

      if (isPentestEnvChange) {
        delete queryCopy.model;
        delete queryCopy.column;
        delete queryCopy.direction;
      }

      replace(
        {
          pathname,
          query: {
            ...(!isOverview ? {} : queryCopy),
            appName: encodeURIComponent(`${selectedCurApp?.name}`),
            versionName: encodeURIComponent(selectedCurVersion?.name as string | number | boolean),
            env: envTypeName,
            start_time_epoch: timestampState?.start,
            end_time_epoch: timestampState?.end
          }
        },
        undefined,
        {}
      );

      if (isPentestEnvChange && initialLoading && additionalCallback) {
        additionalCallback();
      }
    }
  };

  // Set App, Set App Query Params & Save App To Storage
  useEffect(() => {
    const isSamplesAppChange =
      (samplesSelectedApp?.appId && samplesSelectedApp?.appId !== selectedAppId) || !samplesSelectedApp;
    const isSamplesPageShouldRender =
      samplesSelectedApp?.versionId &&
      (samplesSelectedApp?.type !== envType ||
        samplesSelectedApp.versionId !== selectedVersion?.id ||
        samplesSelectedApp?.timestamp?.start !== timestamp.start ||
        samplesSelectedApp?.timestamp?.end !== timestamp.end);

    if ((!isFetching && initialLoading && isSamplesPageShouldRender) || isSamplesAppChange) {
      selectedAppId &&
        selectedVersion &&
        handleQueryChange(selectedApp?.name, selectedVersion?.name, envType, {
          start: timestamp.start,
          end: timestamp.end
        });
      setSelectedApp({
        appId: selectedAppId,
        versionId: selectedApp?.versions?.length ? Number(selectedVersion?.id) : NaN,
        type: envType,
        timestamp: timestamp
      });
      setStorageItem(storageKeys.app, {
        appId: selectedAppId,
        appName: selectedApp?.name,
        versionId: Number(selectedVersion?.id),
        versionName: selectedVersion?.name ?? null,
        type: envType,
        timestamp: timestamp
      });

      isSamplesAppChange && resetFiltersOnAppChange && resetFiltersOnAppChange();
    }
  }, [selectedAppId, selectedVersion, envType, timestamp?.start, timestamp?.end]);

  useEffect(() => {
    // Time Stamp Default Selection On Env/App Change
    const isEnvChanged = envType && envQuery && envQuery !== envType;
    const isAppChanged = appName && appNameQuery && appNameQuery !== appName;

    if (!isFetching && (!!isAppChanged || !!isEnvChanged)) {
      handleResetDates();
    }

    // If there is no versions reset the version dropdown
    if (!selectedApp?.versions?.length) {
      const resetVersions = setTimeout(() => {
        setVersion('');
        replace({ pathname, query: { ...query, versionName: undefined } });
      }, 500);

      return () => clearTimeout(resetVersions);
    }
  }, [envType, appName]);

  useEffect(() => {
    refetch();
  }, [refreshValue]);

  useEffect(() => {
    if (Array.isArray(applications) && applications.length && !isFetching) {
      const findApplicationByNameFromQuery = applications.find(el => el.name === appNameQuery);
      const findApplicationByNameFromStore = applications.find(el => el.name === appName);
      const findVersionByNameFromQuery = Array.isArray(findApplicationByNameFromQuery?.versions)
        ? findApplicationByNameFromQuery?.versions.find(el => el.name === versionNameQuery)
        : undefined;
      const findVersionByNameFromStore = Array.isArray(findApplicationByNameFromStore?.versions)
        ? findApplicationByNameFromStore?.versions.find(el => el.name === versionName)
        : undefined;

      const curAppNameData =
        findApplicationByNameFromQuery ||
        (appName && appName !== '' && appName && findApplicationByNameFromStore) ||
        selectedApp ||
        applications[0];
      const curAppVersionNameData =
        findVersionByNameFromQuery ||
        findApplicationByNameFromQuery?.versions?.[0] ||
        (versionName && versionName !== '' && findVersionByNameFromStore) ||
        selectedVersion ||
        (appName !== '' && appName && findApplicationByNameFromStore?.versions?.[0]) ||
        applications[0].versions[0];

      const curEnvType = envQuery || envType || samplesSelectedApp?.type || undefined;
      curAppNameData && setApplication(curAppNameData.name);
      curAppVersionNameData && setVersion(curAppVersionNameData.name);

      handleQueryChange(curAppNameData.name, curAppVersionNameData?.name, curEnvType, {
        start: timestamp?.start,
        end: timestamp?.end
      });

      setSelectedApp({
        appId: curAppNameData.id,
        versionId: selectedApp?.versions?.length && curAppVersionNameData?.id,
        type: envType,
        timestamp: timestamp
      });
      setStorageItem(storageKeys.app, {
        appId: curAppNameData.id,
        appName: curAppNameData.name,
        versionId: curAppVersionNameData?.id,
        versionName: curAppVersionNameData?.name ?? null,
        timestamp: timestamp,
        type: envType
      });

      setInitialLoading && setInitialLoading(true);
    }
  }, [applications, isFetching]);

  if ((applications as unknown as resError)?.error_message) {
    return (
      <InternalServerError
        isFetched={isFetched && !isFetching}
        isError={!!(applications as unknown as resError)?.error_message}
      />
    );
  } else if (!applications?.length && !(selectedAppId && selectedVersion) && isFetched && !isFetching) {
    return <OverviewNoAppList />;
  }

  return (
    <>
      <AppFiltersContainer>
        <AppFiltersEnvContainer>
          <StyledSelect
            state={envType}
            selections={srcRadioOptions()}
            setState={setEnvType as (state: string | number | null | undefined) => void}
            textOnlyEndAdornmentWhenActive={
              refresh && (
                <StyledToolTip text={constants.refreshTooltip}>
                  <div>
                    <RotateContainer rotate={isFetching} width={24} height={24}>
                      <StyledRefreshIcon onClick={refresh} />
                    </RotateContainer>
                  </div>
                </StyledToolTip>
              )
            }
            connected
            textOnly
          />
        </AppFiltersEnvContainer>
        <AppFiltersInnerContainer>
          {ignoreTimeFilter ? (
            <></>
          ) : (
            <AppFiltersInnerItemContainer>
              <AppFiltersTime timestamp={timestamp} setTimestamp={setTimestamp} handleResetDates={handleResetDates} />
            </AppFiltersInnerItemContainer>
          )}
          <AppFiltersInnerItemContainer>
            <StyledLabelText text={constants.version.label} type="bodyBold" />
            <AppFiltersVersionsDropdown
              value={version}
              minWidth="200px"
              data={selectedApp?.versions || []}
              label={constants.version.placeholder}
              data-testid="AppFiltersVersionDropdown"
              bottomBtn={{
                label: constants?.addVersionBtnLabel,
                action: () => push(routes?.config?.versions),
                icon: <StyledAddCircleOutline />
              }}
              setValue={setVersion}
              noEmptyText
            />
          </AppFiltersInnerItemContainer>
          <AppFiltersInnerItemContainer>
            <StyledLabelText text={constants.application.label} type="bodyBold" />
            <AppFiltersVersionsDropdown
              value={application}
              data={applications || []}
              label={constants.application.placeholder}
              data-testid="AppFiltersApplicationDropdown"
              bottomBtn={{ label: constants?.manageBtnLabel, action: () => push(routes?.config?.applications) }}
              setValue={handleApplicationChange}
              searchField
            />
          </AppFiltersInnerItemContainer>
        </AppFiltersInnerContainer>
      </AppFiltersContainer>
      {samplesNoData && <SamplesNoVersionSelected />}
    </>
  );
};
