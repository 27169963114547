import React, { useState } from 'react';

import dayjs from 'dayjs';

import { Popover } from '@mui/material';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';

import { StyledButton, StyledDateTimePicker, StyledText } from '@/components/lib';
import { AppFiltersDatePopupContainer, AppFiltersTimeFilterButton, StyledLabelText } from '../AppFilters.styles';

import { getTimestamp } from '@/helpers/utils/getTimestamp';

import { constants } from '../appFilters.constants';

interface AppFiltersTimeProps {
  timestamp: { start: number; end: number };
  handleResetDates: () => void;
  setTimestamp: (time: { start: number; end: number }) => void;
}

const { start, end, label, all, resetBtnLabel } = constants.time;

export const AppFiltersTime = (props: AppFiltersTimeProps) => {
  const { timestamp, setTimestamp, handleResetDates } = props;

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleResetDatesAndClose = () => {
    handleResetDates();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const noStartOrEnd = !timestamp?.start || !timestamp?.end;
  const id = open ? 'simple-popover' : undefined;

  const handleStartDateChange = (newStartDate: string | null) => {
    if (newStartDate) {
      const newStartEpoch = dayjs(newStartDate).unix();
      setTimestamp({ end: timestamp?.end || getTimestamp(0), start: newStartEpoch });
    }
  };

  const handleEndDateChange = (newEndDate: string | null) => {
    if (newEndDate) {
      const newEndEpoch = dayjs(newEndDate).set('hour', 23).set('minute', 59).unix();
      const newEndEpochMinusOneMonth = dayjs(newEndDate).subtract(1, 'month').unix();

      setTimestamp({ start: timestamp?.start || newEndEpochMinusOneMonth, end: newEndEpoch });
    }
  };

  return (
    <>
      <StyledLabelText text={label} type="bodyBold" />
      <AppFiltersTimeFilterButton
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownOutlined />}
      >
        {noStartOrEnd
          ? all
          : `${dayjs(timestamp.start * 1000).format('ll')} - ${dayjs(timestamp.end * 1000).format('ll')}`}
      </AppFiltersTimeFilterButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <AppFiltersDatePopupContainer>
          <StyledText text={start} type="small" />
          <StyledDateTimePicker
            value={timestamp.start ? dayjs(timestamp.start * 1000).format('ll') : undefined}
            maxDate={timestamp.end ? dayjs(timestamp.end * 1000).toDate() : dayjs(Date.now()).toDate()}
            handleSetFunction={handleStartDateChange}
          />
          <StyledText text={end} type="small" marginTop="16px" />
          <StyledDateTimePicker
            maxDate={dayjs(Date.now()).toDate()}
            value={timestamp.end ? dayjs(timestamp.end * 1000).format('ll') : undefined}
            minDate={timestamp.start ? dayjs(timestamp.start * 1000).toDate() : undefined}
            handleSetFunction={handleEndDateChange}
          />
          <StyledButton label={resetBtnLabel} onClick={handleResetDatesAndClose} variant="text" />
        </AppFiltersDatePopupContainer>
      </Popover>
    </>
  );
};
