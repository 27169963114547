import React, { useState, useEffect, useRef } from 'react';

import { GeneralMetadataChunk, TextEntrySchema } from '@/helpers/services/api';
import { getMarkedText } from '@/helpers/utils/getMarkedText';

import {
  SamplesDetailsBodyInfoRetrievalItemContainer,
  StyledInfoRetrievalText
} from './SamplesDetailsBodyInfoRetrievalItem.styles';

interface SamplesDetailsBodyInfoRetrievalItemProps {
  index: number;
  isTranslation?: boolean;
  infoRetrievalItem: TextEntrySchema;
  markingIndexes?: GeneralMetadataChunk;
}

const constants = {
  documentTitle: (number: number) => `Document #${number}`
};

const { documentTitle } = constants;

export const SamplesDetailsBodyInfoRetrievalItem = (props: SamplesDetailsBodyInfoRetrievalItemProps) => {
  const { infoRetrievalItem, isTranslation, index, markingIndexes } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const dragTimeout = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (dragTimeout.current !== null) {
        clearTimeout(dragTimeout.current);
      }
    };
  }, []);

  const handleMouseDown = () => {
    setIsDragging(false);

    dragTimeout.current = window.setTimeout(() => {
      setIsDragging(true);
    }, 100);
  };

  const handleMouseUp = () => {
    if (dragTimeout.current !== null) {
      clearTimeout(dragTimeout.current);
    }

    if (!isDragging) {
      setIsOpen(!isOpen);
    }

    setIsDragging(false);
  };

  return (
    <SamplesDetailsBodyInfoRetrievalItemContainer
      translation={!!isTranslation}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      is_collapse={isOpen.toString()}
      onMouseMove={() => setIsDragging(true)}
      data-testid={documentTitle(index + 1)}
    >
      <StyledInfoRetrievalText
        type="bodyBold"
        is_margin="true"
        text={documentTitle(index + 1)}
        is_collapse={isOpen.toString()}
      />
      <StyledInfoRetrievalText
        type="body"
        is_collapse={isOpen.toString()}
        text={getMarkedText(infoRetrievalItem.data, markingIndexes, 'Retrieval Relevance')}
      />
    </SamplesDetailsBodyInfoRetrievalItemContainer>
  );
};
