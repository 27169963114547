import { CircularProgress, FormControlLabel, Stack, styled } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

import { StyledContainer, StyledText } from '../lib';

export const OnboardingContainer = styled(StyledContainer)(() => ({
  width: '100%',
  height: '100%',
  minHeight: '100vh'
}));

export const OnboardingCardContainer = styled(StyledContainer)(() => ({
  borderRadius: '20px',
  width: '100%',
  maxWidth: '654px',
  gap: '24px',
  textAlign: 'left',
  margin: 'auto',
  padding: '36px'
}));

export const OnboardingLoadingContainer = styled(StyledContainer)(() => ({
  margin: 'auto',
  width: '350px',
  flexDirection: 'row'
}));

export const OnboardingLoadingText = styled(StyledText)(({ theme }) => ({
  lineHeight: '44px',
  color: theme.palette.grey[500]
}));

export const OnboardingTitle = styled(StyledText)(() => ({
  margin: '4px 0 8px'
}));

export const OnboardingButtonsContainer = styled(StyledContainer)(() => ({
  padding: '8px 0 0',
  flexDirection: 'row',
  justifyContent: 'space-between',

  '.MuiButtonBase-root': {
    height: '36px'
  }
}));

export const OnboardingErrText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: '-24px'
}));

export const OnboardingFileContainer = styled(StyledContainer, {
  shouldForwardProp: prop => prop !== 'file'
})<{ file: boolean }>(({ file, theme }) => ({
  padding: '0',
  transition: '0.3s',
  marginBottom: '56px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.primary.main,
  border: `2px dashed ${theme.palette.primary.main}`,
  backgroundColor: file ? theme.palette.primary.light : 'transparent',

  '.MuiButtonBase-root': {
    width: '100%',
    padding: '8px 16px',
    justifyContent: 'left',
    transition: 'all 0.3s'
  },

  '.MuiSvgIcon-root': {
    marginRight: '8px',
    cursor: 'pointer'
  },

  '.MuiTypography-root': {
    fontWeight: 600,
    margin: 'auto 24px',
    position: 'absolute',
    color: theme.palette.primary.main
  },

  input: {
    opacity: 0,
    width: '600px',
    height: '54px',
    cursor: 'pointer',
    color: 'transparent'
  }
}));

export const OnboardingContentContainer = styled(StyledContainer)(() => ({
  gap: '16px',
  margin: 0,
  padding: 0,

  '& > .MuiFormControl-root > .MuiTypography-subtitle2': {
    marginTop: '16px'
  }
}));

export const OnboardingDialogContentContainer = styled(StyledContainer)(() => ({
  gap: '24px',
  margin: '16px 0',
  padding: 0,
  maxWidth: '562px'
}));

export const StyledDescriptionText = styled(StyledText)({
  margin: '22px 0 8px'
});

export const StyledSelectContainer = styled(Stack)<{ disabled?: string }>(({ disabled }) => ({
  gap: '8px',
  opacity: disabled === 'true' ? 0.6 : 1
}));

export const StyledAddCircleOutline = styled(AddCircleOutline)(({ theme }) => ({
  fill: theme.palette.primary.main
}));

export const OnboardingRowGapContainer = styled(StyledContainer)({
  gap: '24px',
  padding: '0',
  display: 'grid',
  alignItems: 'flex-end',
  gridTemplateColumns: '1fr 1fr'
});

export const OnboardingLoader = styled(CircularProgress)({
  margin: 'auto'
});

interface OnboardingFormControlLabelProps {
  omit: boolean;
}

export const OnboardingFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== 'omit'
})<OnboardingFormControlLabelProps>(({ omit, theme }) => ({
  padding: '0 2px',
  marginBottom: '-16px',

  '.MuiTypography-root': {
    color: omit ? theme.palette.primary.main : theme.palette.grey[500],
    fontWeight: 600
  }
}));
